import React from "react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

const HeroSection = () => {
    const animeData = [
        {
            title: "Attack on Titan",
            description:
                "In a world where humanity is on the brink of extinction, a group of heroes rises to combat the monstrous Titans.",
            image: "https://cdn.myanimelist.net/images/anime/10/47347.jpg",
        },
        { 
            title: "Demon Slayer",
            description:
                "Follow Tanjiro as he embarks on a journey to save his sister and battle powerful demons.",
            image: "https://cdn.myanimelist.net/images/anime/1286/99889.jpg",
        },
        {
            title: "One Piece",
            description:
                "Join Luffy and his crew as they search for the ultimate treasure, the One Piece, in this epic adventure.",
            image: "https://cdn.myanimelist.net/images/anime/6/73245.jpg",
        },
    ];

    return (
        <div className="text-white w-full h-3">
           
        </div>
    );
};

export default HeroSection;
